import {Component, OnInit} from '@angular/core';
import {ReviewTopic} from '@core/model/review';
import {ActivatedRoute, Router} from '@angular/router';
import {Page} from '@core/model/page';
import {ReviewTopicService} from '@core/service/review-topic.service';
import {ReviewObjectFilter} from '@core/model/review-object-filter';
import {SearchPageDataModel} from '@core/model/page/search-page-data-model';
import {CategoryService} from '@core/service/category.service';
import {PageConfig} from "@core/model/page-config";

@Component({
  selector: 'app-search-review-object-page',
  templateUrl: './search-review-object-page.component.html',
  styleUrls: ['./search-review-object-page.component.css']
})
export class SearchReviewObjectPageComponent implements OnInit {
  reviews?: Page<ReviewTopic>;
  reviewObjectFilter: ReviewObjectFilter = {};
  searchPageDataModel: SearchPageDataModel = {};

  constructor(private route: ActivatedRoute,
              private router: Router,
              private reviewObjectService: ReviewTopicService,
              private categoryService: CategoryService) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(value => {
      this.reviewObjectFilter = {
        q: value['q'],
        sort: value['sort'],
        category: value['category'],
        page: value['page'] ?? 1,
        size: 10
      };
      this.reviewObjectService.searchReviewObject(this.reviewObjectFilter)
      .subscribe(page => this.reviews = page);
      if (this.reviewObjectFilter?.category) {
        this.categoryService.getCategoryParents(this.reviewObjectFilter?.category)
        .subscribe(parentCategories => this.searchPageDataModel.categoryBreadcrumb = parentCategories);
      }

      if (this.reviewObjectFilter?.category) {
        this.categoryService.getCategoryChild(this.reviewObjectFilter?.category)
        .subscribe(parentCategories => this.searchPageDataModel.childCategories = parentCategories);
      }
    });
  }


  changePage(page: any) {
    if (this.reviewObjectFilter) {
      page = page as PageConfig;
      this.reviewObjectFilter = {...this.reviewObjectFilter, page: page.currentPage};
      this.reviewObjectFilter = {...this.reviewObjectFilter, size: page.pageSize};
      this.reviewObjectService.searchReviewObject(this.reviewObjectFilter)
      .subscribe(pageRes => {
        this.reviews = pageRes;
        window.scrollTo(0, 0);
      });
      this.syncParam();
    }
  }

  selectedSortingString(): string {
    switch (this.reviewObjectFilter?.sort ?? this.getDefaultSort()) {
      case 'BEST':
        return 'Сначала лучшие';
      case 'MOST_SIMILAR':
        return 'Сначала похожие';
      case 'POPULAR':
        return 'Сначала популярные';
    }

    return '';
  }

  getDefaultSort() {
    return this.reviewObjectFilter?.q ? 'MOST_SIMILAR' : 'POPULAR';
  }

  sortBy(type: string) {
    this.reviewObjectFilter.sort = type;
    this.syncParam();
  }

  changeCategory(categoryId: number) {
    this.reviewObjectFilter.category = categoryId;
    this.syncParam();
  }

  clearCategory() {
    this.reviewObjectFilter.category = undefined;
    this.searchPageDataModel.childCategories = undefined;
    this.syncParam();
  }

  createParams() {
    let param = {};

    if (this.reviewObjectFilter?.q) {
      param = {...param, q: this.reviewObjectFilter?.q};
    }
    if (this.reviewObjectFilter?.sort) {
      param = {...param, sort: this.reviewObjectFilter?.sort};
    }
    if (this.reviewObjectFilter?.category) {
      param = {...param, category: this.reviewObjectFilter?.category};
    }
    if (this.reviewObjectFilter?.page) {
      param = {...param, page: this.reviewObjectFilter?.page};
    }
    return param;
  }

  syncParam() {
    const param = this.createParams();
    this.router.navigate([`reviews`],
      {
        queryParams: param
      }
    ).then();
  }
}
