import {Component, Input} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ReviewDetail} from '@core/model/review';
import {ReviewerService} from "@core/service/reviewer.service";
import {Category} from "@core/model/category";
import {CategoryService} from "@core/service/category.service";
import {getStatusName} from "@core/model/enums/review-status";
import {DomSanitizer} from "@angular/platform-browser";
import {environment} from "environments/environment";

@Component({
  selector: 'app-user-review-modification-page',
  templateUrl: './user-review-modification-page.component.html',
  styleUrls: ['./user-review-modification-page.component.css']
})
export class UserReviewModificationPageComponent {
  reviewDetail?: ReviewDetail;
  form: FormGroup;

  categoryList: Category[] = [];

  reviewContent: string = '';

  @Input()
  topicImageUrls: Map<number, string> = new Map();
  @Input()
  reviewImageUrls: Map<number, string> = new Map();

  getBasePhotoUrl = environment.basePhotoUrl;

  constructor(private fb: FormBuilder,
              private route: ActivatedRoute,
              private reviewerService: ReviewerService,
              private categoryService: CategoryService,
              private sanitizer: DomSanitizer) {
    this.form = this.fb.group({
      reviewTopicTitle: new FormControl('', [Validators.required]),
      reviewTopicCategory: new FormControl('', [Validators.required]),
      reviewTopicDescription: new FormControl('', [Validators.required]),
      reviewTitle: new FormControl('', [Validators.required]),
      reviewShortDescription: new FormControl('', [Validators.required]),
      reviewContent: new FormControl('', [Validators.required, Validators.minLength(500)]),
    });

    const reviewId = this.route.snapshot.paramMap.get('reviewId');
    if (reviewId) {
      this.categoryService.getCatalog().subscribe(catalog => {
        this.categoryList = this.categoryService.catalogToCategories(catalog);
        this.reviewerService.getReviewById(parseInt(reviewId, 10))
        .subscribe(value => {
          this.reviewDetail = value;

          this.form.get('reviewTopicTitle')?.setValue(this.reviewDetail.reviewTopic?.name);
          this.form.get('reviewTopicCategory')?.setValue(this.reviewDetail.reviewTopic?.category.id);
          this.form.get('reviewTopicDescription')?.setValue(this.reviewDetail.reviewTopic?.description);

          this.form.get('reviewTitle')?.setValue(this.reviewDetail.title);
          this.form.get('reviewShortDescription')?.setValue(this.reviewDetail.shortDescription);
          if (this.reviewDetail?.blocks && this.reviewDetail?.blocks.length > 0) {
            this.reviewContent = this.reviewDetail?.blocks[0].text ?? '';
          }

          this.reviewDetail?.media?.forEach((value, index) => {
            this.reviewImageUrls.set(index + 1, value.largeImage);
          });

          this.reviewDetail?.reviewTopic?.media?.forEach((value, index) => {
            this.topicImageUrls.set(index + 1, value.largeImage);
          });
          console.log(this.reviewImageUrls)
          console.log(this.topicImageUrls)
          this.form.disable()
          this.form.get('reviewContent')?.disable()
        });
      });
    }
  }

  trustHtml(html: string) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  protected readonly getStatusName = getStatusName;
}
