<div class="bg-white p-2 d-block">
  <div>
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-3 col-12 d-flex align-items-center">
          <a [routerLink]="'/'" class="btn nav-link icon-link">
            <img alt="logo" class="logo_img" loading="lazy" src="assets/logo/logo-64.png"><span class="company_name">Ұсынамын</span>
          </a>
        </div>

        <div class="col-md-6 col-12 my-2 my-md-2 d-flex align-items-center header_input">
          <mat-form-field (keyup.enter)="search()" [formGroup]="form" appearance="outline"
                          class="full-width header_input" subscriptSizing="dynamic">
            <mat-label>Поиск</mat-label>
            <input formControlName="search" matInput placeholder="Поиск" type="search" value="">
            <mat-icon (click)="search()" matSuffix>search</mat-icon>
          </mat-form-field>
        </div>

        <div *ngIf="!this.reviewer" class="col-12 col-md-3 d-flex justify-content-between justify-content-md-end">
          <a [routerLink]="'/login'" class="text-decoration-none" color="secondary" mat-stroked-button>Войти</a>
        </div>
        <div *ngIf="this.reviewer" class="col-3 d-flex justify-content-end">
          <div class="btn-group" dropdown>
            <button aria-controls="dropdown-user" bsDropdownToggle
                    class="btn btn-sm dropdown-toggle"
                    type="button">
              <img *ngIf="reviewer?.iconLink" [ngSrc]="this.reviewer.iconLink" alt="user_icon"
                   class="rounded-circle logo_img" height="32"
                   loading="lazy" width="32">
              <img *ngIf="!reviewer?.iconLink" [ngSrc]="'assets/img/no-image.jpg'" alt="no-image"
                   class="rounded-circle logo_img"
                   height="32" loading="lazy" width="32">
              {{this.reviewer.name}}
            </button>
            <ul *bsDropdownMenu class="dropdown-menu text-small">
              <li><a class="dropdown-item" routerLink="/user">Профиль</a></li>
              <li><a class="dropdown-item" routerLink="/user/reviews">Рецензии</a></li>
              <li>
                <hr class="dropdown-divider">
              </li>
              <li>
                <button (click)="logout()" class="text-decoration-none w-100 dropdown-item">Выход
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="border-top mt-2">
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="category-container mt-1">
            <div *ngFor="let group of categoryGroupList" class="category-item">
              <button (click)="showCategoryReviewObjects(group.id)" class="btn m-0">{{group.name}}</button>
            </div>
            <div class="category-item">
              <a [routerLink]="'categories'" class="btn m-0">Другие</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
