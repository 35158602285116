import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Category} from '@core/model/category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  baseUrl = '/api/category';

  constructor(private http: HttpClient) {
  }

  primaryCategoryGroup(): Observable<Category[]> {
    return this.http.get<Category[]>(`${this.baseUrl}/primary`);
  }

  getCategoryParents(categoryId: number): Observable<Category[]> {
    return this.http.get<Category[]>(`${this.baseUrl}/${categoryId}/parent`);
  }

  getCategoryChild(categoryId: number): Observable<Category[]> {
    return this.http.get<Category[]>(`${this.baseUrl}/${categoryId}/child`);
  }

  getCatalog(): Observable<Category[]> {
    return this.http.get<Category[]>(`${this.baseUrl}/catalog`);
  }

  catalogToCategories(categories: Category[]) {
    let categoryList = [];

    for (let categoryL1 of categories) {
      for (let category of categoryL1.categories) {
        categoryList.push({
          id: category.id,
          name: `${categoryL1.name} -> ${category.name}`,
          categories: []
        });
      }
    }

    return categoryList;
  }
}
