import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {Media} from "@core/model/review";
import {CarouselModule} from "ngx-bootstrap/carousel";
import {interval, Subscription} from "rxjs";
import {environment} from "environments/environment";

interface Slide {
  image: Media;
  slideClass: string,
  loaded: boolean;
}

@Component({
  selector: 'app-carousel-image',
  standalone: true,
  imports: [CommonModule, CarouselModule, NgOptimizedImage],
  templateUrl: './carousel-image.component.html',
  styleUrl: './carousel-image.component.css',
})
export class CarouselImageComponent implements OnInit, OnDestroy {
  @Input() images: Media[] = []; // Массив URL изображений
  @Input() autoSlide = false;     // Автоматическая прокрутка
  @Input() slideInterval = 3000;  // Интервал прокрутки в миллисекундах
  @Input() infinite = false;       // Бесконечный цикл
  @Input() mode: string;       // Бесконечный цикл
  @Input() showNoImage: boolean = true;       // Бесконечный цикл


  currentIndex = 0;
  slideSubscription?: Subscription;
  maxVisibleIndicators = 5;
  slides: Slide[] = [];
  preloadCount = 1;


  constructor() {
    this.mode = 'lg';
  }

  ngOnInit(): void {
    this.slides = this.images.map((media) => ({image: media, loaded: false, slideClass: 'd-none'}));

    this.loadNearbySlides();

    if (this.autoSlide) {
      this.startAutoSlide();
    }
  }

  ngOnDestroy(): void {
    this.stopAutoSlide();
  }

  // Переключение на определенный слайд
  selectImage(index: number): void {
    this.currentIndex = index;
  }

  // Предыдущий слайд
  onPreviousClick(): void {
    if (this.currentIndex === 0) {
      this.currentIndex = this.infinite ? this.images.length - 1 : 0;
    } else {
      this.currentIndex--;
    }
  }

  // Следующий слайд
  onNextClick(): void {
    if (this.currentIndex === this.images.length - 1) {
      this.currentIndex = this.infinite ? 0 : this.images.length - 1;
    } else {
      this.currentIndex++;
    }

    this.loadNearbySlides();
  }

  // Начать автоматическую прокрутку
  startAutoSlide(): void {
    this.slideSubscription = interval(this.slideInterval).subscribe(() => {
      this.onNextClick();
    });
  }

  // Остановить автоматическую прокрутку
  stopAutoSlide(): void {
    if (this.slideSubscription) {
      this.slideSubscription.unsubscribe();
    }
  }

  // Получить стиль трансформации для сдвига слайдов
  getTransform(): string {
    return `translateX(-${this.currentIndex * 100}%)`;
  }

  getImageUrl(image: Media) {
    switch (this.mode) {
      case 'lg':
        return environment.basePhotoUrl + image.largeImage
      case 'md':
        return environment.basePhotoUrl + image.mediumImage
      case 'sm':
        return environment.basePhotoUrl + image.smallImage
    }


    return environment.basePhotoUrl + image.largeImage;
  }

  get visibleIndicators(): number[] {
    const total = this.images.length;
    const max = this.maxVisibleIndicators;
    let start = Math.max(0, this.currentIndex - Math.floor(max / 2));
    let end = start + max;

    if (end > total) {
      end = total;
      start = Math.max(0, end - max);
    }

    const indicators = [];
    for (let i = start; i < end; i++) {
      indicators.push(i);
    }

    return indicators;
  }

  loadNearbySlides(): void {
    const total = this.slides.length;
    const start = Math.max(0, this.currentIndex - this.preloadCount);
    const end = Math.min(total, this.currentIndex + this.preloadCount + 1);

    for (let i = start; i < end; i++) {
      if (!this.slides[i].loaded) {
        this.slides[i].loaded = true;
        this.slides[i].slideClass = 'd-block';
      }
    }
  }
}
