<div *ngIf="reviewTopic" class="container mt-3 mb-3">
  <div class="row">
    <div class="col-md-9 col-12">
      <section [class.mat-elevation-z1]="true" id="review_object">
        <div class="row">
          <div class="col">
            <h2 class="text-center mt-2">{{ reviewTopic.name }}</h2>
            <app-carousel-image *ngIf="reviewTopic && reviewTopic.media" [images]="reviewTopic.media"></app-carousel-image>
            <div class="d-flex align-items-end mt-2">
              <app-rating [readonly]="true" [selectedRating]="reviewTopic.rating"></app-rating>
              <span class="mb-1 mx-2">{{ reviewTopic.rating }}/5</span>
            </div>
          </div>
        </div>
      </section>

      <div class="col-md-3 col-12" *ngIf="isMobile()">
        <app-simple-yandex-ad-block></app-simple-yandex-ad-block>
      </div>

      <section *ngIf="reviewTopic.productStatistic.KASPI && reviewTopic.productStatistic.KASPI.priceHistory && reviewTopic.productStatistic.KASPI.priceHistory.length > 0" [class.mat-elevation-z1]="true" class="mt-3"
               id="kaspi-product-statistic">
        <app-kaspi-statistic [productStatistic]="reviewTopic.productStatistic.KASPI" title="kaspi.kz">
        </app-kaspi-statistic>
      </section>

      <section *ngIf="reviewTopic.productStatistic.HALYKMARKET && reviewTopic.productStatistic.HALYKMARKET.priceHistory && reviewTopic.productStatistic.HALYKMARKET.priceHistory.length > 0" [class.mat-elevation-z1]="true" class="mt-3"
               id="halykmarket-product-statistic">
        <app-kaspi-statistic [productStatistic]="reviewTopic.productStatistic.HALYKMARKET" title="halykmarket.kz">
        </app-kaspi-statistic>
      </section>

      <section *ngIf="reviewTopic.description" [class.mat-elevation-z1]="true" class="mt-3"
               id="review_topic_description">
        <h4>Описание</h4>
        <div [innerHTML]="trustHtml(reviewTopic.description)" class="mt-3 review_block">
        </div>
      </section>

      <section [class.mat-elevation-z1]="true" class="mt-3" id="review">
        <ul class="nav nav-tabs" role="list">
          <li role="listitem" class="nav-item">
            <button class="nav-link active" data-bs-toggle="tab" href="#pane-review" id="tab-review">Отзывы</button>
          </li>
          <li role="listitem" *ngFor="let menu of reviewTopic.menus" class="nav-item">
            <a href="#pane-{{menu.id}}" [id]="'tab-' + menu.id" class="nav-link" target="_self"
               data-bs-toggle="tab">{{ menu.name }}</a>
          </li>
        </ul>

        <div class="tab-content" id="content" role="tablist">
          <div aria-labelledby="tab-review" class="tab-pane fade show active" id="pane-review"
               role="tabpanel">
            <div *ngIf="reviews">
              <div *ngFor="let review of reviews.content" class="mt-2 mb-2">
                @switch (isMobile()){
                  @case (true){
                    <app-review-card [review]="review">
                    </app-review-card>
                  }
                  @default {
                    <app-review-card-inline [review]="review">
                    </app-review-card-inline>
                  }
                }
              </div>
              <app-paginator (pageChange)="changePage($event)"
                             [hidePageSize]="false"
                             [pageSizeOptions]="[10, 25, 100]"
                             [totalPages]="reviews.pages"
                             class="mt-2">
              </app-paginator>
            </div>
          </div>
          <ng-container *ngFor="let menu of reviewTopic.menus">
            <div [attr.aria-labelledby]="'tab-' + menu.id" id="pane-{{menu.id}}" class="tab-pane fade show"
                 role="tabpanel">

              <div *ngFor="let item of menu.items" class="mt-2">
                <div class="mt-2 mb-2">
                  <app-carousel-image *ngIf="item && item.media" [images]="item.media" [showNoImage]="false"></app-carousel-image>
                </div>
                <div [innerHTML]="trustHtml(item.text)">
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </section>

      <div class="mt-2" *ngIf="isAuth">
        <a mat-raised-button class="w-100" [routerLink]="['/user/review/add']"
           [queryParams]="{reviewTopicUrl: reviewTopic.url}">Оставить отзыв</a>
      </div>

      <div class="mt-2 bg-white p-3 d-flex" *ngIf="!isAuth" [class.mat-elevation-z1]="true">
        <p class="m-0">Чтобы оставить отзыв нужно <a routerLink="/login" class="text-decoration-none">авторизоваться</a>
        </p>
      </div>
    </div>
    <div class="col-md-3 col-12" *ngIf="!isMobile()">
      <app-simple-yandex-ad-block></app-simple-yandex-ad-block>
    </div>
  </div>
</div>

<app-floor-adsblock/>
