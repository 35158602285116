import {CommonModule} from '@angular/common';
import {Editor} from "ngx-editor";

import {Component, Input, OnInit} from '@angular/core';
import {Plugin, PluginKey} from 'prosemirror-state';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {BsModalService} from "ngx-bootstrap/modal";
import {Subject} from "rxjs";
import {Media} from "@core/model/review";
import {UploadPhotoModalComponent} from "@core/component/upload-photo-modal/upload-photo-modal.component";
import {environment} from "environments/environment";

@Component({
  selector: 'app-review-editor-menu',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule],
  templateUrl: './review-editor-menu.component.html',
  styleUrl: './review-editor-menu.component.css'
})
export class ReviewEditorMenuComponent implements OnInit {
  @Input()
  editor?: Editor;
  isActive = false;
  isDisabled = false;
  photoLoaded: Subject<Media> = new Subject();


  constructor(private modalService: BsModalService) {
    this.photoLoaded.subscribe(media => this.insertPhotoEditor(media))
  }

  onClickAddImage(e: MouseEvent): void {
    e.preventDefault();
    if (this.editor) {
      this.modalService.show(UploadPhotoModalComponent, {
        animated: true,
        keyboard: false,
        class: 'modal-dialog-centered',
        initialState: {photoLoaded: this.photoLoaded}
      });
    }
  }

  insertPhotoEditor(media: Media) {
    if (this.editor) {
      this.editor.commands
      .insertImage(`${environment.basePhotoUrl}${media.largeImage}`)
      .scrollIntoView()
      .exec();
    }
  }


  ngOnInit(): void {
    const plugin = new Plugin({
      key: new PluginKey(`custom-menu-codemirror`),
    });

    this.editor?.registerPlugin(plugin);
  }
}
